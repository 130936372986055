
import { defineComponent, inject } from 'vue';

import DarkModeToggle from '@/components/AppHeader/DarkModeToggle.vue';
import Filters from '@/components/AppHeader/Filters.vue';
import Search from '@/components/Search/Search.vue';

import ArrowLeftIcon from '@/icons/ArrowLeft.vue';
import HomeIcon from '@/icons/Home.vue';
import MagnifyIcon from '@/icons/Magnify.vue';
import MenuIcon from '@/icons/Menu.vue';

import { useCollapsible } from '@/hooks/collapsible';

export default defineComponent({
  components: {
    ArrowLeftIcon,
    DarkModeToggle,
    Filters,
    HomeIcon,
    MagnifyIcon,
    MenuIcon,
    Search,
  },
  setup() {
    const { isCollapsed: isSearchCollapsed, setCollapsed: setSearchCollapsed } = inject(
      'searchCollapsible',
      useCollapsible(),
    );
    const {
      isCollapsed: isSidebarCollapsed,
      setCollapsed: setSidebarCollapsed,
      toggleCollapse: toggleSidebarCollapse,
    } = inject('sidebarCollapsible', useCollapsible());

    function goHome() {
      window.location.href = 'https://raid-gaming.net';
    }

    function onSearchClick() {
      setSidebarCollapsed(true);
      setSearchCollapsed(false);
    }

    return {
      goHome,
      isSearchCollapsed,
      isSidebarCollapsed,
      onSearchClick,
      setSearchCollapsed,
      toggleSidebarCollapse,
    };
  },
});
