
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    fullPage: {
      type: Boolean,
      required: false,
    },
    visible: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
});
