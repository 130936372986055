
import { defineComponent, inject } from 'vue';

import { useCollapsible } from '@/hooks/collapsible';
import { useMethods } from '@/hooks/methods';

export default defineComponent({
  props: {
    categoryId: {
      type: String,
      required: true,
    },
    method: {
      type: Object,
      required: true,
    },
    namespace: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { navigateToMethod } = useMethods();
    const { setCollapsed } = inject('sidebarCollapsible', useCollapsible());

    function onMethodClick() {
      const { categoryId, method, namespace } = props;
      navigateToMethod(categoryId, namespace, method.name);
      setCollapsed(true);
    }

    return {
      onMethodClick,
    };
  },
});
