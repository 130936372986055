
import { defineComponent } from 'vue';

import WeatherNightIcon from '@/icons/WeatherNight.vue';
import WeatherSunnyIcon from '@/icons/WeatherSunny.vue';

import { useTheme } from '@/hooks/theme';

export default defineComponent({
  components: {
    WeatherNightIcon,
    WeatherSunnyIcon,
  },
  setup() {
    const { isDarkModeEnabled, toggleTheme } = useTheme();

    return {
      isDarkModeEnabled,
      toggleTheme,
    };
  },
});
