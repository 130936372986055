
import { defineComponent } from 'vue';

import SearchResults from '@/components/Search/SearchResults.vue';

import CloseIcon from '@/icons/Close.vue';
import MagnifyIcon from '@/icons/Magnify.vue';

import { useSearch } from '@/hooks/search';

export default defineComponent({
  components: {
    SearchResults,
    CloseIcon,
    MagnifyIcon,
  },
  props: {
    mobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const { clear, results, value } = useSearch();

    return {
      clear,
      results,
      value,
    };
  },
});
