
import { defineComponent, inject } from 'vue';

import SidebarCategory from '@/components/AppSidebar/SidebarCategory.vue';

import { useCollapsible } from '@/hooks/collapsible';
import { useMethods } from '@/hooks/methods';

export default defineComponent({
  components: {
    SidebarCategory,
  },
  setup() {
    const { isCollapsed } = inject('sidebarCollapsible', useCollapsible());
    const { filteredApi } = useMethods();

    return { filteredApi, isCollapsed };
  },
});
