
import { defineComponent, inject } from 'vue';

import { useCollapsible } from '@/hooks/collapsible';
import { useMethods } from '@/hooks/methods';
import { ISearchResult } from '@/hooks/search';

export default defineComponent({
  props: {
    mobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    results: {
      type: Array,
      required: true,
    },
  },
  emits: ['clear'],
  setup(_, { emit }) {
    const { setCollapsed } = inject('searchCollapsible', useCollapsible());
    const { navigateToMethod } = useMethods();

    function getKey(value: ISearchResult) {
      return `${value.category}-${value.namespace}-${value.name}`;
    }

    function goToMethod(result: ISearchResult) {
      emit('clear');
      navigateToMethod(result.categoryId, result.namespace, result.name);
      setCollapsed(true);
    }

    return {
      getKey,
      goToMethod,
    };
  },
});
