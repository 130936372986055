
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    block: {
      type: Boolean,
      required: false,
    },
    color: {
      type: String,
      required: false,
      default: 'secondary',
      validator: (color: string) => ['primary', 'secondary'].includes(color),
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    fab: {
      type: Boolean,
      required: false,
    },
    flat: {
      type: Boolean,
      required: false,
    },
    large: {
      type: Boolean,
      required: false,
    },
    outline: {
      type: Boolean,
      required: false,
    },
    rounded: {
      type: Boolean,
      required: false,
    },
    small: {
      type: Boolean,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: 'button',
      validator: (type: string) => ['button', 'menu', 'reset', 'submit'].includes(type),
    },
  },
});
