
import { defineComponent } from 'vue';

import SidebarMethod from '@/components/AppSidebar/SidebarMethod.vue';

import ChevronDownIcon from '@/icons/ChevronDown.vue';

import { useCollapsible } from '@/hooks/collapsible';

export default defineComponent({
  components: {
    ChevronDownIcon,
    SidebarMethod,
  },
  props: {
    categoryId: {
      type: String,
      required: true,
    },
    namespace: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isCollapsed, toggleCollapse } = useCollapsible(true, props.categoryId);

    return {
      isCollapsed,
      toggleCollapse,
    };
  },
});
