
import { defineComponent } from 'vue';

import Banner from '@/components/Banner/Banner.vue';

export default defineComponent({
  components: {
    Banner,
  },
});
