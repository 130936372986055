
import { defineComponent } from 'vue';

import DarkModeToggle from '@/components/AppHeader/DarkModeToggle.vue';
import Filters from '@/components/AppHeader/Filters.vue';
import Search from '@/components/Search/Search.vue';

import ArrowRightIcon from '@/icons/ArrowRight.vue';

export default defineComponent({
  components: {
    ArrowRightIcon,
    DarkModeToggle,
    Filters,
    Search,
  },
});
