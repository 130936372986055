
import { computed, defineComponent, onBeforeMount, provide } from 'vue';

import AppFooter from '@/components/AppFooter/AppFooter.vue';
import AppHeader from '@/components/AppHeader/AppHeader.vue';
import AppHeaderMobile from '@/components/AppHeader/AppHeaderMobile.vue';
import AppSidebar from '@/components/AppSidebar/AppSidebar.vue';
import Spinner from '@/components/Spinner/Spinner.vue';

import { useCollapsible } from '@/hooks/collapsible';
import { useMethods } from '@/hooks/methods';
import { useTheme } from '@/hooks/theme';

export default defineComponent({
  components: {
    AppFooter,
    AppHeader,
    AppHeaderMobile,
    AppSidebar,
    Spinner,
  },
  setup() {
    const searchCollapsible = useCollapsible(true, 'searchCollapsible');
    const sidebarCollapsible = useCollapsible(true, 'sidebarCollapsible');
    const { fetchIndex, isLoaded } = useMethods();
    const { theme } = useTheme();

    provide('searchCollapsible', searchCollapsible);
    provide('sidebarCollapsible', sidebarCollapsible);

    const themeId = computed(() => `theme-${theme.value}`);

    onBeforeMount(() => {
      fetchIndex();
    });

    return { isLoaded, themeId };
  },
});
