
import { computed, defineComponent } from 'vue';

import { useFilters } from '@/hooks/filters';

function getButtonColor(isActive: boolean) {
  return isActive ? 'primary' : 'secondary';
}

export default defineComponent({
  setup() {
    const { filters, toggleMP, toggleSP } = useFilters();

    const buttonColorMP = computed(() => getButtonColor(filters.value.multiplayer));
    const buttonColorSP = computed(() => getButtonColor(filters.value.singleplayer));

    return {
      filters,
      toggleMP,
      toggleSP,
      buttonColorMP,
      buttonColorSP,
    };
  },
});
