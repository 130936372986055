
import { defineComponent } from 'vue';

import SidebarNamespace from '@/components/AppSidebar/SidebarNamespace.vue';

import ChevronDownIcon from '@/icons/ChevronDown.vue';

import { useCollapsible } from '@/hooks/collapsible';

export default defineComponent({
  components: {
    ChevronDownIcon,
    SidebarNamespace,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { collapseAll, expand, expandAll: expandAllChildren, isCollapsed, toggleCollapse } = useCollapsible();

    function expandAll(categoryId: string) {
      expand();
      expandAllChildren(categoryId);
    }

    return {
      collapseAll,
      expandAll,
      isCollapsed,
      toggleCollapse,
    };
  },
});
